<template>
  <div id="mian" class="clear">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>审核状态</i>
          <el-select
            v-model="query.checkStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="待审核" value="WAIT"></el-option>
            <el-option label="已通过" value="PASS"></el-option>
            <el-option label="已驳回" value="REJECT"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>激活状态</i>
          <el-select
            v-model="query.activateStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未激活" value="NOTACTIVATE"></el-option>
            <el-option label="已激活" value="ACTIVATE"></el-option>
            <el-option label="已冻结" value="FROZEN"></el-option>
            <el-option label="已关闭" value="CLOSED"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>

        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="onSearch"
            >查询</el-button
          >
          <el-button
            v-auth="'ACCOUNT:MCH:MCHS/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 192px">
            <p>创建时间</p>
          </td>
          <td style="width: 220px">
            <p>商户名称</p>
          </td>
          <td style="width: 130px">
            <p>商户编号</p>
          </td>
          <td style="width: 183px">
            <p>所属代理商</p>
          </td>
          <td style="width: 140px">
            <p>审核状态</p>
          </td>
          <td style="width: 75px">
            <p>激活状态</p>
          </td>
          <td style="width: 93px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 192px">
            <p>{{ v.createTime }}</p>
          </td>
          <td style="width: 220px">
            <p>{{ v.mchName }}</p>
          </td>
          <td style="width: 130px">
            <p>{{ v.mchId }}</p>
          </td>
          <td style="width: 183px">
            <p>{{ v.agentName }}</p>
          </td>
          <td style="width: 140px">
            <p>
              {{
                v.checkStatus == "WAIT"
                  ? "待审核"
                  : v.checkStatus == "PASS"
                  ? "已通过"
                  : v.checkStatus == "REJECT"
                  ? "已驳回"
                  : ""
              }}
            </p>
          </td>
          <td style="width: 75px">
            <p>
              {{
                v.activateStatus == "NOTACTIVATE"
                  ? "未激活"
                  : v.activateStatus == "ACTIVATE"
                  ? "已激活"
                  : v.activateStatus == "FROZEN"
                  ? "已冻结"
                  : v.activateStatus == "CLOSED"
                  ? "已关闭"
                  : ""
              }}
            </p>
          </td>
          <td style="width: 93px">
            <p>
              <i
                v-auth="'ACCOUNT:MCH:MCHS/DETAIL'"
                class="lianjie"
                @click="
                  $router.push({
                    name: 'businessList_shxq',
                    query: { id: v.mchId },
                  })
                "
                >详情</i
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shopLists } from "@/api/user/shopManager.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        checkStatus: "",
        activateStatus: "",
        keyword: "",
      },
      tabData: [],
      totalNum: 0,
    };
  },
  computed: {
    ...mapState({
      businessQueryDetail: "user_businessQuery",
    }),
  },
  created() {
    if (this.businessQueryDetail) {
      this.query = this.businessQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.onSearch);
  },
  methods: {
    ...mapMutations({
      setBusinessQuery: "user_setBusinessQuery",
      setBusiness: "user_setBusiness",
    }),
    //新增按钮
    addclickHander() {
      this.setBusiness(null);
      this.$router.push("/user/businessList_shlb/addcommercial_jbxx");
    },
    // 获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      shopLists(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.merchants;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 搜索
    onSearch() {
      this.getLists();
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setBusinessQuery(data);
  },
};
</script>
<style scoped>

</style>
